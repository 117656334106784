import { Navigation } from "../_models/index";

export const navigation: Navigation[] = [
  {
    id: "cms_dashboards",
    title: "Tổng quan",
    translate: "NAV.DASHBOARDS",
    type: "items",
    icon: "home",
    url: "/cms/dashboard",
    exactMatch: true,
  },

  {
    id: "cms_animalgenus",
    title: "Động vật chí",
    translate: "NAV.ANIMAL",
    type: "group",
    icon: "user",
    url: "/cms/animalgenus",
    exactMatch: true,
    children: [
      {
        id: "cms_animalgenus_classificationstructure",
        title: "Cấu trúc phân bậc",
        translate: "NAV.ANIMAL_LIST.CLASSIFICATION_STRUCTURE",
        type: "items",
        url: "/cms/animalgenus/classification-structure",
        exactMatch: true,
        children: [
          {
            id: "cms_animalgenus_listclassificationstructure",
            title: "Danh sách nhập liệu",
            translate: "NAV.ANIMAL_LIST.ANIMAL_DETAIL.LIST_CLASSIFICATION_STRUCTURE",
            type: "item",
            url: "/cms/animalgenus/classification-structure",
            exactMatch: true
          },
          {
            id: "cms_animalgenus_listclassificationstructure",
            title: "Danh sách duyệt",
            translate: "NAV.ANIMAL_LIST.ANIMAL_DETAIL.CHECK_LIST",
            type: "item",
            url: "/cms/animalgenus/classification-structure",
            exactMatch: true
          },
          {
            id: "cms_animalgenus_listclassificationstructure",
            title: "Danh sách duyệt",
            translate: "NAV.ANIMAL_LIST.ANIMAL_DETAIL.BROWER_LIST",
            type: "item",
            url: "/cms/animalgenus/classification-structure",
            exactMatch: true
          },
          {
            id: "cms_animalgenus_listclassificationstructure",
            title: "Danh sách công bố",
            translate: "NAV.ANIMAL_LIST.ANIMAL_DETAIL.LIST_ANNOUNCED",
            type: "item",
            url: "/cms/animalgenus/classification-structure",
            exactMatch: true
          },
          {
            id: "cms_animalgenus_listclassificationstructure",
            title: "Lịch sử cập nhật",
            translate: "NAV.ANIMAL_LIST.ANIMAL_DETAIL.HISTORY_UPDATE",
            type: "item",
            url: "/cms/animalgenus/history-classification-structure",
            exactMatch: true
          },
        ]
      },
      {
        id: "cms_animalgenus_datastructure",
        title: "Cấu trúc dữ liệu",
        translate: "NAV.ANIMAL_LIST.DATA_STRUCTURE",
        type: "items",
        url: "/cms/animalgenus/data-structure",
        exactMatch: true,
        children: [
          {
            id: "cms_animalgenus_datastructure",
            title: "Danh sách nhập liệu",
            translate: "NAV.ANIMAL_LIST.ANIMAL_DETAIL.LIST_CLASSIFICATION_STRUCTURE",
            type: "item",
            url: "/cms/animalgenus/data-structure",
            exactMatch: true
          },
        ]
      },
    ]
  },

  {
    id: "cms_plantlice",
    title: "Thực vật chí",
    translate: "NAV.PLANT",
    type: "group",
    icon: "user",
    url: "/cms/plantslice",
    exactMatch: true,
    children: [
      {
        id: "cms_plantlice_classificationstructure",
        title: "Cấu trúc phân bậc",
        translate: "NAV.PLANT_LIST.CLASSIFICATION_STRUCTURE",
        type: "items",
        url: "/cms/plantslice/classification-structure-plant",
        exactMatch: true,
        children: [
          {
            id: "cms_plantlice_listclassificationstructure",
            title: "Danh sách nhập liệu",
            translate: "NAV.PLANT_LIST.PLANT_DETAIL.LIST_CLASSIFICATION_STRUCTURE",
            type: "item",
            url: "/cms/plantslice/classification-structure-plant",
            exactMatch: true
          },
        ]
      },
      {
        id: "cms_plantlice_datastructure",
        title: "Cấu trúc dữ liệu",
        translate: "NAV.PLANT_LIST.DATA_STRUCTURES",
        type: "items",
        exactMatch: true,
        url: "/cms/plantslice/data-structures",
        children: [
          {
            id: "cms_plantlice_datastructure_child",
            title: "Danh sách nhập liệu",
            translate: "NAV.PLANT_LIST.PLANT_DETAIL.LIST_DATA_STRUCTURE",
            type: "items",
            url: "/cms/plantslice/data-structure-plant",
            exactMatch: true
          }
        ]
      }
    ]
  },
  {
    id: "cms_lookup",
    title: "Tra cứu",
    translate: "NAV.LOOKUP",
    type: "group",
    icon: "list",
    url: "/cms/lookup",
    exactMatch: true,
    children: [
      // Phần Tra cứu
    ],
  },
  // {
  //   id: "cms_plantlice",
  //   title: "Thực vật chí",
  //   translate: "NAV.PLANT",
  //   type: "group",
  //   icon: "user",
  //   url: "/cms/plantlice",
  //   exactMatch: true,
  //   children: [
  //     {
  //       id: "cms_plantlice_plantstructure",
  //       title: "Cấu trúc bậc phân loại Thực vật chí",
  //       translate: "NAV.PLANT_LIST.PLANT_STRUCTURE",
  //       type: "items",
  //       url: "/cms/plantlice/plant-structure",
  //       exactMatch: true,
  //       children: [
  //         {
  //           id: "cms_plantlice_list_plantmanager",
  //           title: "Quản lý kiểm tra cấu trúc bậc phân loại TVC",
  //           translate: "NAV.PLANT_LIST.PLANT_STRUCTURE_LIST.PLANT_MANAGER",
  //           type: "item",
  //           url: "/cms/plantlice/plant-manager",
  //           exactMatch: true
  //         },
  //       ]
  //     },
  //   ]
  // },
  {
    id: "cms_report",
    title: "Báo cáo",
    translate: "NAV.REPORT",
    type: "items",
    icon: "home",
    url: "/cms/report",
    exactMatch: true
  },
  {
    id: "cms_masterdata",
    title: "Danh mục",
    translate: "NAV.MASTER_DATA",
    type: "group",
    icon: "list",
    url: "/cms/masterdata",
    exactMatch: true,
    children: [
      {
        id: "cms_masterdata_organization",
        title: "Đơn vị/Phòng ban",
        translate: "NAV.MASTER_DATA_LIST.ORGANIZATION",
        type: "item",
        url: "/cms/masterdata/organization",
        exactMatch: true
      },
      {
        id: "cms_masterdata_title",
        title: "Chức vụ",
        translate: "NAV.MASTER_DATA_LIST.TITLE",
        type: "item",
        url: "/cms/masterdata/title",
        exactMatch: true
      },
      {
        id: "cms_masterdata_meetroom",
        title: "Phòng họp",
        translate: "NAV.MASTER_DATA_LIST.MEET_ROOM",
        type: "item",
        url: "/cms/masterdata/meetroom",
        exactMatch: true
      },
      {
        id: "cms_masterdata_tabletype",
        title: "Loại hình bàn họp",
        translate: "NAV.MASTER_DATA_LIST.TABLE_TYPE",
        type: "item",
        url: "/cms/masterdata/tabletype",
        exactMatch: true
      },
      {
        id: "cms_masterdata_meetgroup",
        title: "Nhóm họp",
        translate: "NAV.MASTER_DATA_LIST.MEET_GROUP",
        type: "item",
        url: "/cms/masterdata/meetgroup",
        exactMatch: true
      },
      {
        id: "cms_masterdata_meetype",
        title: "Loại hình cuộc họp",
        translate: "NAV.MASTER_DATA_LIST.MEET_TYPE",
        type: "item",
        url: "/cms/masterdata/meetype",
        exactMatch: true
      },
      {
        id: "cms_masterdata_notificationtemplate",
        title: "Mẫu thông báo",
        translate: "NAV.MASTER_DATA_LIST.NOTIFICATION_TEMPLATE",
        type: "item",
        url: "/cms/masterdata/notificationtemplate",
        exactMatch: true
      },
      {
        id: "cms_masterdata_province",
        title: "Danh mục Tỉnh/Thành phố",
        translate: "NAV.MASTER_DATA_LIST.PROVINCE",
        type: "item",
        url: "/cms/masterdata/province",
        exactMatch: true
      },
      {
        id: "cms_masterdata_district",
        title: "Danh mục Quận/Huyện/Thị xã",
        translate: "NAV.MASTER_DATA_LIST.DISTRICT",
        type: "item",
        url: "/cms/masterdata/district",
        exactMatch: true
      },
      {
        id: "cms_masterdata_ward",
        title: "Danh mục tỉnh Xã/Phường/Thị Trấn",
        translate: "NAV.MASTER_DATA_LIST.WARD",
        type: "item",
        url: "/cms/masterdata/ward",
        exactMatch: true
      },
      {
        id: "cms_masterdata_publishing",
        title: "Danh mục nhà xuất bản",
        translate: "NAV.MASTER_DATA_LIST.PUBLISHING",
        type: "item",
        url: "/cms/masterdata/publishing",
        exactMatch: true
      },
      {
        id: "cms_masterdata_book",
        title: "Sách",
        translate: "NAV.MASTER_DATA_LIST.BOOK",
        type: "item",
        url: "/cms/masterdata/book",
        exactMatch: true
      },
    ],
  },
  {
    id: "cms_authen",
    title: "Quản trị người dùng",
    translate: "NAV.AUTHEN",
    type: "group",
    icon: "user",
    url: "/cms/authen",
    exactMatch: true,
    children: [
      {
        id: "cms_authen_groupuser",
        title: "Nhóm quyền người dùng",
        translate: "NAV.AUTHEN_LIST.GROUP_USER",
        type: "item",
        url: "/cms/authen/groupuser",
        exactMatch: true
      },
      {
        id: "cms_authen_user",
        title: "Người dùng hệ thống",
        translate: "NAV.AUTHEN_LIST.USER",
        type: "item",
        url: "/cms/authen/user",
        exactMatch: true
      },
      {
        id: "cms_authen_function",
        title: "Chức năng",
        translate: "NAV.AUTHEN_LIST.FUNCTION",
        type: "item",
        url: "/cms/authen/function",
        exactMatch: true
      },
      {
        id: "cms_authen_action",
        title: "Hành động",
        translate: "NAV.AUTHEN_LIST.ACTION",
        type: "item",
        url: "/cms/authen/action",
        exactMatch: true
      },
      {
        id: "cms_authen_diaryhistory",
        title: "Nhật ký hệ thống",
        translate: "NAV.AUTHEN_LIST.DIARYHISTORY",
        type: "item",
        url: "/cms/authen/diaryhistory",
        exactMatch: true
      },
      {
        id: "cms_authen_viewsmanager",
        title: "Nhật ký hệ thống",
        translate: "NAV.AUTHEN_LIST.VIEWSMANAGER",
        type: "item",
        url: "/cms/authen/viewsmanager",
        exactMatch: true
      },
      {
        id: "cms_authen_configurationsystem",
        title: "Cấu hình hệ thống",
        translate: "NAV.AUTHEN_LIST.CONFIGURATIONSYSTEM",
        type: "item",
        url: "/cms/authen/configurationsystem",
        exactMatch: true
      }
    ]
  },
];
