export const locale = {
  lang: "vi",
  data: {
    NAV: {
      DASHBOARDS: "Dashboard",
      MASTER_DATA: "Danh mục",
      AUTHEN: "Hệ thống",
      LOOKUP: "Tra cứu",
      // PLANT: "Thực vật chí",
      EXTENSION: "Tiện ích",
      // PLANT_LIST: {
      //   PLANT_STRUCTURE: "Cấu trúc bậc phân loại Thực vật chí",
      //   PLANT_STRUCTURE_LIST: {
      //     PLANT_MANAGER: "Quản lý kiểm tra cấu trúc bậc phân loại TVC",
      //   },
      // },
      READ_RECORD: "Gỡ băng",
      REPORT: "Báo cáo",
      ANIMAL: "Động vật chí",
      PLANT:"Thực vật chí",
      MASTER_DATA_LIST: {
        PROVINCE: "Danh mục Tỉnh/Thành phố",
        DISTRICT: "Danh mục Quận/Huyện/Thị xã",
        WARD: "Danh mục Xã/Phường/Thị Trấn",
        ORGANIZATION: "Đơn vị/Phòng ban",
        TITLE: "Chức vụ",
        MEET_ROOM: "Phòng họp",
        TABLE_TYPE: "Loại hình bàn họp",
        MEET_GROUP: "Nhóm họp",
        MEET_TYPE: "Loại hình cuộc họp",
        NOTIFICATION_TEMPLATE: "Mẫu thông báo",
        //huy
        PUBLISHING: "Danh mục Nhà xuất bản",
        //huy
        BOOK : "Sách",
      },
      LOOKUP_LIST: {

      },
      AUTHEN_LIST: {
        GROUP_USER: "Nhóm quyền người dùng",
        FUNCTION: "Chức năng",
        ACTION: "Hành động",
        USER: "Người dùng hệ thống",
        DIARYHISTORY: "Nhật ký hệ thống",
        SETTINGS: "Cài đặt chung",
        VIEWSMANAGER: "Quản lý lượt xem",
        CONFIGURATIONSYSTEM: "Cấu hình hệ thống",
      },
      REPORT_LIST: {
      },
      ANIMAL_LIST: {
        CLASSIFICATION_STRUCTURE: "Cấu trúc phân bậc",
        DATA_STRUCTURE: "Cấu trúc dữ liệu",
        ANIMAL_DETAIL: {
          LIST_CLASSIFICATION_STRUCTURE: "Danh sách nhập liệu",
          CHECK_LIST: "Danh sách kiểm tra",
          BROWER_LIST: "Danh sách duyệt",
          LIST_ANNOUNCED: "Danh sách công bố",
          HISTORY_UPDATE: "Lịch sử cập nhật",
        },
      },

      PLANT_LIST: {
        CLASSIFICATION_STRUCTURE: "Cấu trúc phân bậc",
        DATA_STRUCTURES: "Cấu trúc dữ liệu",
        PLANT_DETAIL: {
          LIST_CLASSIFICATION_STRUCTURE: "Danh sách nhập liệu",
          LIST_DATA_STRUCTURE: "Danh sách nhập liệu",
          CHECK_LIST: "Danh sách kiểm tra",
          BROWER_LIST: "Danh sách duyệt",
          LIST_ANNOUNCED: "Danh sách công bố",
          HISTORY_UPDATE: "Lịch sử cập nhật",
        },
      },
    }
  }
};
